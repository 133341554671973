/* xl */
@media only screen and (min-width: 1200px) {
	/* Background */
	.bg-height-responsive {
		min-height: 820vmax;
	}

	/* Navbar */
	.justify-end-responsive {
		justify-content: end !important;
	}
	.flex-responsive {
		display: flex;
	}
	.navbar-nav-responsive {
		padding-top: 1.5rem;
	}
	.button-nav-responsive {
		position: relative;
		/* top: 70%;
		left: -5%;
		z-index: 10; */
	}
	.nav-item-product {
		padding-right: 1.5rem;
	}
	.nav-item-2 {
		padding: 0 1.5rem;
	}
	.nav-item-contact {
		padding-left: 1.5rem;
	}

	.navbar-menu-responsive{
		width: 65px;
		height: 65px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.1);
		cursor: pointer;
	}

	.navbar-menu-responsive img{
		width: 20px;
		height: 20px;
		margin: 22px 22px;
	}

	.navbar-modal-button{
		justify-content: end;
		margin-top: 32px;
		margin-bottom: 80px;
		margin-right: 48px;
	}

	.exit-nav-modal{
		width: 65px;
		height: 65px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.1);
		cursor: pointer;
	}

	.exit-nav-modal img{
		width: 20px;
		height: 20px;
		margin: 22px 22px;
	}

	.backdrop-modal-nav{
		width: 100%;
		height: 100vh;
		background-image: url(../image/navModal.png);
		background-color: black;
		object-fit: cover;
	}

	.navbar-content-modal{
		justify-content: center;
		align-items: center;
		gap: 120px;
	}

	.nav-content-bio h1{
		font-size: 30px;
	}

	.nav-content-bio p{
		font-size: 14px;
		width: 494px;
	}

	.button-head-office{
		width: 259px;
		font-size: 16px;
		padding: 8px 0px;
		cursor: pointer;
	}

	.button-office{
		width: 217px;
		font-size: 16px;
		padding: 8px 0px;
		cursor: pointer;
	}

	.nav-content-list{
		font-size: 42px;
	}
	/* Landing */
	.h-landing-responsive {
		height: 128vh;
		width: 100%;
	}
	.padding-top-landing {
		padding-top: 4rem;
	}
	.image-landing {
		position: absolute;
		width: 65%;
		top: 14%;
		right: -5%;
	}
	.text-landing-responsive {
		width: 85%;
	}
	.landing-bottom-responsive {
		margin-bottom: 14%;
	}

	.btn-landing-responsive {
		width: 220px;
		height: 54px;
		margin-top: 50px;
	}

	.btn-landing-responsive > .svg-wrapper-1{
		margin-left: 25px;
	}

	.btn-landing-responsive span{
		font-size: 20px;
	}

	/* Product */
	.h-products-responsive {
		height: 125vh;
	}
	.top-responsive-products {
		margin-bottom: 5rem;
		padding-top: 3rem;
	}
	.mb-responsive-products {
		margin-bottom: 7rem;
	}
	.product-bottom-responsive {
		margin-bottom: 18%;
	}

	.h-products-responsive {
		height: 145vh;
	}

	.top-responsive-products {
		margin-bottom: 10rem;
		padding-top: 3rem;
	}

	.rectangle{
		width: 50px;
		height: 50px;
		border: 3px solid #FFC107;
		margin-top: 247px;
	}

	.products-wrap{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.top-products-wrap{
		width: 540px;
	}

	.title-products-responsive{
		font-size: 30px;
	}

	.titleBot-products-responsive{
		font-size: 52px;
		line-height: 50px;
	}

	.content-title-product{
		left: 20px;
		bottom: 20px;
		font-size: 15px;
	}

	.hover-title-product{
		font-size: 15px;
		margin-top: 120px;
	}

	.content-poster-product{
		width: 270px;
	}

	.content-desc-product{
		width: 270px; 
		height: 274px; 
		position: absolute;
		background: rgba(235, 111, 62, 0.75);
		bottom: 0;
	}

	.hover-desc-product{
		font-size: 13px;
	}

	/* Experience */
	.h-experience-responsive {
		height: 110vh;
	}
	.item img.card-experience {
		width: 22rem;
		/* height: 50%; */
	}
	.experience-bottom-responsive {
		margin-bottom: 10%;
	}

	.top-experience-wrap{
		margin-bottom: 118px;
	}

	.title-experience-responsive{
		font-size: 48px;
		margin-bottom: 17.68px;
	}

	.titleBot-experience-responsive{
		font-size: 26px;
		width: 905px;
		margin: auto;
	}

	.showCard-experience{
		margin-bottom: 94.32px;
	}

	.base-content-experience{
		gap: 58px;
	}

	.content-experience-wrap{
		gap: 80px;
	}

	.experience-text-porto{
		font-size: 17.8659px;
		font-weight: 800;
		line-height: 29px;
	}

	.experience-text-title{
		width: 671.19px;
		font-weight: 700;
		font-size: 40px;
		line-height: 46px;
		color: #FFFFFF;
	}

	.experience-text-desc{
		width: 473.52px;
		height: 62px;
		font-weight: 500;
		font-size: 17.8659px;
		line-height: 150%;
		letter-spacing: -0.02em;
		color: rgba(255, 255, 255, 0.75);
	}

	.content-experience-image{
		width: 465px;
	}
	

	.btn-showAll{
		width: 284px; 
		padding-top: 8px;
		padding-bottom: 8px; 
		font-size: 18px;
		font-weight: 700;
		cursor: pointer;
	}

	.top-modal-experience-wrap{
		text-align: center;
		margin-bottom: 50px;
		font-size: 50px;
	}

	.top-modal-arrow{
		font-size: 15px; 
        font-style: italic;
        display: inline;
	}

	.back-button-modal{
		width: 100px !important;
		margin-top: 30px;
		margin-left: 50px;
	}

	.base-modal-content{
		gap: 70px;
	}

	.modal-content-wrap{
		justify-content: space-between;
		align-items: center;
		gap: 70px;
	}

	.modal-content-wrap h1{
		width: 671.19px; 
		color: #FFFFFF; 
		font-size: 44px;
	}

	.modal-content-wrap p{
		width: 535.52px;
		font-size: 19.8659px;
	}

	.modal-content-wrap img{
		width: 480px;
	}

	.modal-content-wrap-2, 
	.modal-content-wrap-4, 
	.modal-content-wrap-6{
		align-items: center;
	}

	.modal-content-wrap-2 h1,
	.modal-content-wrap-4 h1,
	.modal-content-wrap-6 h1{
		width: 642.19px; 
		color: #FFFFFF; 
		font-size: 40px;
	}

	.modal-content-wrap-2 p,
	.modal-content-wrap-4 p,
	.modal-content-wrap-6 p{
		width: 535.52px;
		font-size: 17.9659px;
	}

	.modal-content-wrap-2 img,
	.modal-content-wrap-4 img,
	.modal-content-wrap-6 img{
		width: 480px;
	}

	/* Team */
	.team-bottom-responsive{
		margin-bottom: 8%;
	}

	.top-team-responsive{
		margin-bottom: 8rem;
	}

	.top-team-responsive h1{
		font-size: 48px;
	}

	.team-poster{
		gap: 30px;
	}

	.main-poster{
		width: 392px;
		height: 393px;
		margin-top: 54px;
		margin-left: 30px;
		padding: 38px;
		border: 1px #68500d solid;
		border-radius: 10px;
	}

	.info-team{
		text-align: center;
		margin: auto;
	}

	.info-team img{
		width: 225px;
		border-radius: 50%;
		margin-bottom: 35px;
	}

	.name-main-poster{
		font-size: 28px;
		line-height: 15px;
	}

	.title-main-poster{
		font-size: 18px;
		color: #9F9F9F;
	}

	.poster-slider{
		width: 60%;
		margin: auto;
	}

	.card-leader-bio{
		text-align: center;
		margin: auto;
	}

	.card-leader-bio img{
		width: 140px;
		height: 140px;
		margin: 30px auto;
		cursor: pointer;
	}

	.name-slider{
		font-size: 14px;
		line-height: 10px;
	}

	.title-slider{
		font-style: italic;
		font-size: 14px;
		color: #9F9F9F;
	}

	.slick-dots{
		margin-bottom: -20px !important;
	}

	.top-team-modal{
		margin-top: 80px;
		margin-bottom: 120px;
		gap: 50px;
	}
	
	.list-leader-modal{
		margin-bottom: 8rem;
	}

	.top-modal-team-left{
		width: 511px;
	}

	.top-modal-title{
		font-size: 44px;
		margin-bottom: 30px;
	}

	.top-modal-desc{
		font-size: 13px;
		margin-bottom: 20px;
	}

	.company-record{
		gap: 10px;
		margin-bottom: 20px;
	}

	.box-record{
		width: 154px;
	}
	
	.box-record-years{
		width: 200px;
	}

	.title-record{
		font-size: 40px;
	}

	.bot-title-record{
		font-size: 15px;
	}

	.top-frame-poto{
		border: 1.61px solid #FFC107;
		margin-top: 15px;
		position: absolute;
	}

	.top-frameOut-poto{
		width: 550px;
		height: 350px;
		border: 1.61px solid #FFC107;
		margin-left: 17px;
	}

	.top-frame-poto img{
		width: 550px;
		height: 350px;
		object-fit: cover;
	}

	.content-team-leader-1{
		gap: 60px;
		justify-content: center;
	}

	.content-team-leader-3,
	.content-team-leader-5{
		gap: 60px;
		justify-content: center;
	}

	.content-team-leader-2,
	.content-team-leader-4 {
		gap: 60px;
		justify-content: center;
		flex-direction: row-reverse;
	}

	.frame-leader-photo-1{
		width: 306.53px;
		height: 445.68px;
		padding: 17px;
		border: 1.5125px solid #FFC107;
		background-color: black;
	}

	.frame-leader-photo-2,
	.frame-leader-photo-3,
	.frame-leader-photo-4,
	.frame-leader-photo-5{
		width: 214.82px;
		height: 311.92px;
		padding: 12px;
		border: 1.5125px solid #FFC107;
		background-color: black;
	}

	.frameOut-leader-photo-1{
		width: 306.53px;
		height: 445.68px;
		border: 1.5125px solid #FFC107;
		background-color: black;
		margin-left: 25px;
		margin-top: 25px;
	}

	.frameOut-leader-photo-2,
	.frameOut-leader-photo-3,
	.frameOut-leader-photo-4,
	.frameOut-leader-photo-5{
		width: 214.82px;
		height: 311.92px;
		border: 1.5125px solid #FFC107;
		background-color: black;
		margin-left: 15px;
		margin-top: 15px;
	}

	.frame-leader-photo-1 img{
		width: 267.96px;
		height: 330.73px;
		margin-bottom: 16.64px;
	}

	.frame-leader-photo-2 img,
	.frame-leader-photo-3 img,
	.frame-leader-photo-4 img,
	.frame-leader-photo-5 img{
		width: 187.91px;
		height: 231.71px;
		margin-bottom: 16.64px;
	}

	.name-frame-leader-1{
		font-size: 24.2px;
		line-height: 30px;
	}

	.name-frame-leader-2,
	.name-frame-leader-3,
	.name-frame-leader-4,
	.name-frame-leader-5{
		font-size: 16.89px;
		line-height: 12px;
	}

	.position-frame-leader-1{
		font-size: 15.13px;
		font-style: italic;
		color: rgba(159, 159, 159, 1);
		line-height: 0px;
	}

	.position-frame-leader-2,
	.position-frame-leader-3,
	.position-frame-leader-4,
	.position-frame-leader-5{
		font-size: 10.55px;
		font-style: italic;
		color: rgba(159, 159, 159, 1);
		line-height: 0px;
	}

	.leader-part-right{
		width: 600px;
		margin-top: auto;
		margin-bottom: auto;
	}

	.content-leader-title{
		font-size: 33px;
	}

	.content-leader-desc{
		font-size: 14px;
		text-align: justify;
	}

	.top-team-title{
		font-size: 50px;
		margin-bottom: 40px;
	}

	.card-content-team{
		gap: 50px;
		justify-content: center;
		flex-wrap: wrap;
	}

	.frame-team-photo{
		width: 221.44px;
		height: 329.54px;
		padding: 9px;
		background: #171717;
		border: 1.175px solid #FFC107;
	}

	.frame-team-photo img{
		width: 201.47px;
		height: 240.24px;
		margin-bottom: 8px;
	}

	.frameOut-team-photo{
		width: 221.44px;
		height: 329.54px;
		background: #171717;
		border: 1.175px solid #FFC107;
		margin-left: 15px;
		margin-top: 15px;
	}

	.name-frame-team{
		font-size: 18.8px;
		margin-bottom: 2px;
	}

	.position-frame-team{
		font-style: italic;
		font-size: 11.75px;
		color: rgba(159, 159, 159, 1);
	}

	/* Contact */
	.h-contact-responsive {
		height: 90vh;
	}
	.bottom-form {
		margin-bottom: 8.5rem;
	}

	
	.btn-contact{
		width: 206.54px;
		height: 53.29px;
	}

	.form-divide{
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 10px;
	}

	.input-divide{
		width: 50%;
	}
}