/* xxl */
@media only screen and (min-width: 1400px) {
	/* Background */
	.bg-height-responsive {
		min-height: 770vmax;
	}

	/* Navbar */
	.justify-end-responsive {
		justify-content: end !important;
	}
	.flex-responsive {
		display: flex;
	}
	.navbar-nav-responsive {
		padding-top: 1.5rem;
	}
	.nav-item-product {
		padding-right: 1.5rem;
	}
	.nav-item-2 {
		padding: 0 1.5rem;
	}
	.nav-item-contact {
		padding-left: 1.5rem;
	}

	.navbar-menu-responsive{
		width: 65px;
		height: 65px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.1);
		cursor: pointer;
	}

	.navbar-menu-responsive img{
		width: 20px;
		height: 20px;
		margin: 22px 22px;
	}

	.navbar-modal-button{
		justify-content: end;
		margin-top: 32px;
		margin-bottom: 80px;
		margin-right: 48px;
	}

	.exit-nav-modal{
		width: 65px;
		height: 65px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.1);
		cursor: pointer;
	}

	.exit-nav-modal img{
		width: 20px;
		height: 20px;
		margin: 22px 22px;
	}

	.backdrop-modal-nav{
		width: 100%;
		height: 100vh;
		background-image: url(../image/navModal.png);
		background-color: black;
		object-fit: cover;
	}

	.navbar-content-modal{
		justify-content: center;
		align-items: center;
		gap: 120px;
	}

	.nav-content-bio h1{
		font-size: 36px;
	}

	.nav-content-bio p{
		font-size: 16px;
		width: 494px;
	}

	.button-head-office{
		width: 259px;
		font-size: 16px;
		padding: 10px 0px;
		cursor: pointer;
	}

	.button-office{
		width: 217px;
		font-size: 16px;
		padding: 10px 0px;
		cursor: pointer;
	}

	.nav-content-list{
		font-size: 52px;
	}

	.navbar-list{
		transition: 0.5s;
	}

	.navbar-list:hover {
		color: #FFC107;
	}

	/* Landing */
	.h-landing-responsive {
		height: 130vh;
		width: 100%;
	}
	.image-landing {
		position: absolute;
		width: 60%;
		top: 17%;
		right: -5%;
	}
	.padding-top-landing {
		padding-top: 6rem;
	}
	.text-landing-responsive {
		width: 85%;
	}
	.landing-bottom-responsive {
		margin-bottom: 15%;
	}

	.btn-landing-responsive {
		width: 220px;
		height: 54px;
		margin-top: 50px;
	}

	.btn-landing-responsive > .svg-wrapper-1{
		margin-left: 25px;
	}

	.btn-landing-responsive span{
		font-size: 20px;
	}

	/* Product */
	.h-products-responsive {
		height: 145vh;
	}

	.top-responsive-products {
		margin-bottom: 10rem;
		padding-top: 3rem;
	}

	.rectangle{
		width: 50px;
		height: 50px;
		border: 3px solid #FFC107;
		margin-top: 277px;
	}

	.products-wrap{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.top-products-wrap{
		width: 600px;
	}

	.title-products-responsive{
		font-size: 32px;
	}

	.titleBot-products-responsive{
		font-size: 54px;
	}

	.content-title-product{
		left: 20px;
		bottom: 20px;
		font-size: 20px;
	}

	.hover-title-product{
		font-size: 20px;
		margin-top: 130px;
	}

	.content-poster-product{
		width: 300px;
	}

	.content-desc-product{
		width: 300px; 
		height: 304px; 
		position: absolute;
		background: rgba(235, 111, 62, 0.75);
		bottom: 0;
	}

	.hover-desc-product{
		font-size: 14px;
	}

	/* Experience */
	.h-experience-responsive {
		height: 120vh;
	}
	.item img.card-experience {
		width: 26rem;
		/* height: 50%; */
	}

	.experience-bottom-responsive {
		margin-bottom: 10%;
	}

	.top-experience-wrap{
		margin-bottom: 128px;
	}

	.title-experience-responsive{
		font-size: 54px;
		margin-bottom: 17.68px;
	}

	.titleBot-experience-responsive{
		font-size: 28px;
		width: 975px;
		margin: auto;
	}

	.showCard-experience{
		margin-bottom: 94.32px;
	}

	.base-content-experience{
		gap: 68px;
	}

	.content-experience-wrap{
		gap: 60px;
	}

	.content-experience-wrap-2{
		gap: 90px;
	}

	.experience-text-porto{
		font-size: 20.8659px;
		font-weight: 800;
		line-height: 29px;
	}

	.experience-text-title{
		width: 671.19px;
		font-weight: 700;
		font-size: 48px;
		line-height: 65px;
		color: #FFFFFF;
	}

	.experience-text-desc{
		width: 593.52px;
		height: 62px;
		font-weight: 500;
		font-size: 20.8659px;
		line-height: 150%;
		letter-spacing: -0.02em;
		color: rgba(255, 255, 255, 0.75);
	}

	.content-experience-image{
		width: 540px;
		height: 405px;
	}

	/* .btn-showAll{
		width: 324px; 
		padding-top: 10px;
		padding-bottom: 10px; 
		font-size: 20px;
		font-weight: 700;
	} */

	.top-modal-experience-wrap{
		text-align: center;
		margin-bottom: 50px;
		font-size: 54px;
	}

	.top-modal-arrow{
		font-size: 18px; 
        font-style: italic;
        display: inline;
	}

	.back-button-modal{
		width: 100px !important;
		margin-top: 30px;
		margin-left: 50px;
	}

	.base-modal-content{
		gap: 70px;
	}

	.modal-content-wrap{
		justify-content: space-around;
		margin: auto;
		gap: 70px;
	}

	.modal-content-wrap h1{
		width: 671.19px; 
		color: #FFFFFF; 
		font-size: 48px;
	}

	.modal-content-wrap p{
		width: 593.52px;
		font-size: 20.8659px;
	}

	.modal-content-wrap img{
		width: 540px;
	}

	.modal-content-wrap-2, 
	.modal-content-wrap-4, 
	.modal-content-wrap-6{
		margin: auto;
		gap: 70px;
	}

	.modal-content-wrap-2 h1,
	.modal-content-wrap-4 h1,
	.modal-content-wrap-6 h1{
		width: 671.19px; 
		color: #FFFFFF; 
		font-size: 48px;
	}

	.modal-content-wrap-2 p,
	.modal-content-wrap-4 p,
	.modal-content-wrap-6 p{
		width: 593.52px;
		font-size: 20.8659px;
	}

	.modal-content-wrap-2 img,
	.modal-content-wrap-4 img,
	.modal-content-wrap-6 img{
		width: 540px;
	}

	/* Team */
	.team-bottom-responsive{
		margin-bottom: 12%;
	}

	.top-team-responsive{
		margin-bottom: 10rem;
	}

	.top-team-responsive h1{
		font-size: 54px;
	}

	.team-poster{
		gap: 40px;
	}

	.main-poster{
		width: 432px;
		height: 433px;
		margin-top: 54px;
		margin-left: 30px;
		padding: 40px;
		border: 2px #68500d solid;
		border-radius: 10px;
	}

	.info-team{
		text-align: center;
		margin: auto;
	}

	.info-team img{
		width: 261px;
		border-radius: 50%;
		margin-bottom: 35px;
	}

	.name-main-poster{
		font-size: 32px;
		line-height: 25px;
	}

	.title-main-poster{
		color: #9F9F9F;
	}

	.poster-slider{
		width: 60%;
		margin: auto;
	}

	.card-leader{
		margin-top: 10px;
	}

	.card-leader-bio{
		text-align: center;
		margin: auto;
	}

	.card-leader-bio img{
		width: 160px;
		height: 160px;
		margin: 30px auto;
		cursor: pointer;
	}

	.name-slider{
		font-size: 16px;
		line-height: 10px;
	}

	.title-slider{
		font-style: italic;
		font-size: 16px;
		color: #9F9F9F;
	}

	.slick-dots{
		margin-bottom: -40px !important;
	}

	.top-team-modal{
		margin-top: 80px;
		margin-bottom: 120px;
		gap: 60px;
	}
	
	.list-leader-modal{
		margin-bottom: 8rem;
	}

	.top-modal-team-left{
		width: 611px;
	}

	.top-modal-title{
		font-size: 54px;
		margin-bottom: 40px;
	}

	.top-modal-desc{
		font-size: 15px;
		margin-bottom: 20px;
	}

	.company-record{
		gap: 10px;
		margin-bottom: 20px;
	}

	.box-record{
		width: 184px;
	}

	.title-record{
		font-size: 48px;
	}

	.bot-title-record{
		font-size: 15px;
	}

	.top-frame-poto{
		border: 1.61px solid #FFC107;
		margin-top: 15px;
		position: absolute;
	}

	.top-frameOut-poto{
		width: 600px;
		height: 400px;
		border: 1.61px solid #FFC107;
		margin-left: 17px;
	}

	.top-frame-poto img{
		width: 600px;
		height: 400px;
		object-fit: cover;
	}

	.content-team-leader-1,
	.content-team-leader-3,
	.content-team-leader-5{
		gap: 80px;
		justify-content: center;
	}

	.content-team-leader-2,
	.content-team-leader-4 {
		gap: 80px;
		justify-content: center;
		flex-direction: row-reverse;
	}

	.frame-leader-photo-1{
		width: 336.53px;
		height: 475.68px;
		padding: 17px;
		border: 1.5125px solid #FFC107;
		background-color: black;
	}

	.frame-leader-photo-2,
	.frame-leader-photo-3,
	.frame-leader-photo-4,
	.frame-leader-photo-5{
		width: 234.82px;
		height: 331.92px;
		padding: 12px;
		border: 1.5125px solid #FFC107;
		background-color: black;
	}

	.frameOut-leader-photo-1{
		width: 336.53px;
		height: 475.68px;
		border: 1.5125px solid #FFC107;
		background-color: black;
		margin-left: 25px;
		margin-top: 25px;
	}

	.frameOut-leader-photo-2,
	.frameOut-leader-photo-3,
	.frameOut-leader-photo-4,
	.frameOut-leader-photo-5{
		width: 234.82px;
		height: 331.92px;
		border: 1.5125px solid #FFC107;
		background-color: black;
		margin-left: 15px;
		margin-top: 15px;
	}

	.frame-leader-photo-1 img{
		width: 297.96px;
		height: 360.73px;
		margin-bottom: 16.64px;
	}

	.frame-leader-photo-2 img,
	.frame-leader-photo-3 img,
	.frame-leader-photo-4 img,
	.frame-leader-photo-5 img{
		width: 207.91px;
		height: 251.71px;
		margin-bottom: 16.64px;
	}

	.name-frame-leader-1{
		font-size: 24.2px;
		line-height: 30px;
	}

	.name-frame-leader-2,
	.name-frame-leader-3,
	.name-frame-leader-4,
	.name-frame-leader-5{
		font-size: 16.89px;
		line-height: 12px;
	}

	.position-frame-leader-1{
		font-size: 15.13px;
		font-style: italic;
		color: rgba(159, 159, 159, 1);
		line-height: 0px;
	}

	.position-frame-leader-2,
	.position-frame-leader-3,
	.position-frame-leader-4,
	.position-frame-leader-5{
		font-size: 10.55px;
		font-style: italic;
		color: rgba(159, 159, 159, 1);
		line-height: 0px;
	}

	.leader-part-right{
		width: 600px;
		margin-top: auto;
		margin-bottom: auto;
	}

	.content-leader-title{
		font-size: 36px;
	}

	.content-leader-desc{
		font-size: 15px;
		text-align: justify;
	}

	.top-team-title{
		font-size: 54px;
		margin-bottom: 40px;
	}

	.card-content-team{
		gap: 50px;
		justify-content: center;
		flex-wrap: wrap;
	}

	.frame-team-photo{
		width: 261.44px;
		height: 369.54px;
		padding: 14px;
		background: #171717;
		border: 1.175px solid #FFC107;
	}

	.frame-team-photo img{
		width: 231.47px;
		height: 280.24px;
		margin-bottom: 8px;
	}

	.frameOut-team-photo{
		width: 261.44px;
		height: 369.54px;
		background: #171717;
		border: 1.175px solid #FFC107;
		margin-left: 20px;
		margin-top: 17px;
	}

	.name-frame-team{
		font-size: 18.8px;
		margin-bottom: 2px;
	}

	.position-frame-team{
		font-style: italic;
		font-size: 11.75px;
		color: rgba(159, 159, 159, 1);
	}

	/* Contact */
	.h-contact-responsive {
		height: 120vh;
	}
	.bottom-form {
		margin-bottom: 8.5rem;
	}

	.btn-contact{
		width: 206.54px;
		height: 53.29px;
	}

	.form-divide{
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 10px;
	}

	.input-divide{
		width: 50%;
	}  
}