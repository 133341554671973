/* Fonts Manrope */
.fm-extrabold {
	font-family: "Manrope", sans-serif;
	font-weight: 800;
}
.fm-bold {
	font-family: "Manrope", sans-serif;
	font-weight: 700;
}
.fm-semibold {
	font-family: "Manrope", sans-serif;
	font-weight: 600;
}
.fm-medium {
	font-family: "Manrope", sans-serif;
	font-weight: 500;
}
.fm-regular {
	font-family: "Manrope", sans-serif;
	font-weight: 400;
}

/* Fonts RedHatDisplay */
.frhd-extrabold {
	font-family: "Red Hat Display", sans-serif;
	font-weight: 800;
}
.frhd-bold {
	font-family: "Red Hat Display", sans-serif;
	font-weight: 700;
}
.frhd-semibold {
	font-family: "Red Hat Display", sans-serif;
	font-weight: 600;
}
.frhd-medium {
	font-family: "Red Hat Display", sans-serif;
	font-weight: 500;
}
.frhd-regular {
	font-family: "Red Hat Display", sans-serif;
	font-weight: 400;
}

/* Smooth Scroll */
.scroll-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	will-change: transform;
}
