@import url("https://fonts.googleapis.com/css2?family=Manrope&family=Red+Hat+Display&display=swap");

.w-80 {
  width: 80%;
}

.w-2rem {
  width: 2rem;
}

.w-3rem {
  width: 3rem;
}

.color-gray {
  color: #3b4559 !important;
}

.color-paragraph {
  color: #f8f9fa;
}

.color-paragraph-darken {
  color: rgb(248, 249, 250, 80%);
}

.bg-fade-yellow {
  background-color: #433b12;
}

.bg-fade-orange {
  background-color: #c29508;
}

.border-bottom-solid {
  border-bottom: solid;
}

.btn-yellow {
  background-color: #ffc107 !important;
  border: none;
  border-radius: 0.5rem;
}

.btn-yellow:hover {
  box-shadow: 0 0 10px #ffe58c;
}

.btn-yellow-contact {
  background-color: #ffc107 !important;
  border: none;
  color: #3b4559;
  border-radius: 0.5rem;
}

.btn-yellow-contact:hover {
  box-shadow: 0 0 8px #ffe58c;
}

.btn-showAll {
  background-color: #ffc107;
  display: inline-block;
  border: none;
}

.btn-showAll:hover {
  box-shadow: 0 0 10px #ffe58c;
}

.color-yellow {
  color: #ffc107;
}

/* Must Responsive */
.logo-footer {
  width: 3rem;
  height: 3rem;
}

.brand-footer {
  height: 3rem;
}

.pointer {
  cursor: pointer;
}

.z-index {
  z-index: 10;
}

.fs-7 {
  font-size: 0.9rem;
}

/* Glassmorphism Form Contact */
.form {
  position: relative;
  width: 100%;
  z-index: 5;
  background: transparent;
  backdrop-filter: blur(30px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.5rem;
}

/* input form */
.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1rem;
  color: #ffc107;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.option {
  color: white;
}

.option:focus option {
  color: #ffc107;
  font-weight: 700;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1rem;
  cursor: text;
  top: 25px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #f2f2f2;
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #ffc107, #fff707);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #ffc107;
  font-weight: 700;
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

/* background */
.main-bg {
  background-image: url(../image/bg-fix.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: black;
}

.hero {
  background-image: url(../image/bg-landing.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.product {
  background-image: url(../image/bg-product.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* Carousel */
.item {
  width: 32%;
}

.item img.card-experience {
  width: 100%;
  height: 50%;
}

.inner-carousel {
  display: flex;
  gap: 1.5rem;
  background: transparent;
}

.carousel {
  cursor: grab;
  overflow: hidden;
}

/* Animate Text on Landing */
.animate-text {
  color: transparent;
  background-image: linear-gradient(
    to right,
    #ecbc33,
    #a24a60,
    #f0b678,
    #f37a77,
    #fef5d6,
    #baf9f8,
    #2bcdeb,
    #5693dd,
    #b5f9e3,
    #dab09a,
    #5dbced,
    #c661e1
  );
  background-clip: text;
  animation: animate 30s linear infinite;
  background-size: 1000%;
}

@keyframes animate {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

/* Animated Assets */
.assets-1 {
  z-index: 0;
  width: 8rem;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.assets-2 {
  z-index: 0;
  width: 8rem;
  position: absolute;
  top: 0px;
  left: 0px;
}

/* Assets Experience */
/* Must Responsive */
.asset-experience {
  z-index: 0;
  width: 8rem;
  position: absolute;
  top: -10px;
  right: -10px;
}

.blink-experience {
  position: absolute;
  z-index: 3;
  top: 0;
  right: -4rem;
}

.blink-experience-darken {
  position: absolute;
  z-index: 2;
  top: -0.5rem;
  right: -1.6rem;
}

/* Stacking Card Experience */
.image-experience {
  z-index: 4;
  width: 90%;
  height: 90%;
  position: absolute;
  top: 14px;
  right: 18px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Card Product */
.card-product:hover .desc-card-0 {
  visibility: visible;
}

.card-product:hover .desc-card-1 {
  visibility: visible;
}

.card-product:hover .desc-card-2 {
  visibility: visible;
}

.card-product:hover .desc-card-3 {
  visibility: visible;
}

.card-product:hover .desc-card-4 {
  visibility: visible;
}

.card-product:hover .desc-card-5 {
  visibility: visible;
}

.card-product:hover .title-card {
  visibility: hidden;
}

.desc-card-0,
.desc-card-1,
.desc-card-2,
.desc-card-3,
.desc-card-4,
.desc-card-5 {
  visibility: hidden;
}

.modalExp::-webkit-scrollbar {
  width: 0;
}
.modalExp {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* modal bootstrap*/

.modal-body {
  background-image: url(../image/bgmodalExp.png) !important;
  background-color: black;
}

.show-modal {
  cursor: pointer !important;
}

.back-button-modal {
  cursor: pointer;
}

/* react slick */

.slick-dots li button:before {
  color: grey !important;
  font-size: 10px !important;
}

.slick-arrow {
  visibility: hidden !important;
}

.slick-dots li.slick-active button:before {
  color: white !important;
}

.slick-current {
  display: none !important;
}

/* icon scroll */
.scrolldown {
  --sizeX: 30px;
  --sizeY: 50px;
  --color: #fff;
  --color: skyblue;
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--color);
  border-radius: 50px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.scrolldown::before {
  content: "";
  position: absolute;
  bottom: 45px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--color);
  border-radius: 100%;
  animation: scrolldown-anim 2s infinite;
  box-sizing: border-box;
  box-shadow: 0px -5px 3px 1px #2a547066;
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }

  40% {
    opacity: 1;
    height: 10px;
  }

  80% {
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }

  100% {
    height: 3px;
    opacity: 0;
  }
}

.chevrons {
  padding: 6px 0 0 0;
  margin-left: -3px;
  margin-top: 48px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chevrondown {
  margin-top: -6px;
  position: relative;
  border: solid var(--color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.chevrondown:nth-child(odd) {
  animation: pulse54012 500ms ease infinite alternate;
}

.chevrondown:nth-child(even) {
  animation: pulse54012 500ms ease infinite alternate 250ms;
}

@keyframes pulse54012 {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

/* button contact header */
.btn-landing-responsive {
  background: royalblue;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s;
}

.btn-landing-responsive span {
  font-family: "Red Hat Display";
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
  color: white;
}

.btn-landing-responsive svg {
  color: white;
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.btn-landing-responsive:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.btn-landing-responsive:hover svg {
  transform: translateX(3.2em) rotate(45deg) scale(1.1);
}

.btn-landing-responsive:hover span {
  transform: translateX(10em);
}

.btn-landing-responsive:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

/* button showall effect */
.button {
  width: 200px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: #0f1923;
  cursor: pointer;
  position: relative;
  padding: 8px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.15s ease;
}

.button::before,
.button::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: calc(50% - 5px);
  border: 1px solid #7d8082;
  transition: all 0.15s ease;
}

.button::before {
  top: 0;
  border-bottom-width: 0;
}

.button::after {
  bottom: 0;
  border-top-width: 0;
}

.button:active,
.button:focus {
  outline: none;
}

.button:active::before,
.button:active::after {
  right: 3px;
  left: 3px;
}

.button:active::before {
  top: 3px;
}

.button:active::after {
  bottom: 3px;
}

.button_lg {
  position: relative;
  display: block;
  padding: 10px 20px;
  color: #fff;
  background-color: #0f1923;
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 1px transparent;
}

.button_lg::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background-color: #0f1923;
}

.button_lg::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  background-color: #0f1923;
  transition: all 0.2s ease;
}

.button_sl {
  display: block;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -8px;
  width: 0;
  background-color: #ffc107;
  transform: skew(-15deg);
  transition: all 0.2s ease;
}

.button_text {
  position: relative;
}

.button:hover {
  color: #0f1923;
}

.button:hover .button_sl {
  width: calc(100% + 15px);
}

.button:hover .button_lg::after {
  background-color: #fff;
}
