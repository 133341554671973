/* xs */
@media only screen and (max-width: 575px) {
  .scrolldown {
    display: none;
  }

  /* Background */
  .bg-height-responsive {
    min-height: 850vmax;
  }

  /* Navbar */
  .navbar-nav-responsive {
    padding-top: 0.5rem;
  }
  .navbar-brand-responsive {
    position: absolute;
    top: 10%;
    right: 41%;
    width: 35% !important;
  }
  .button-nav-responsive {
    position: absolute;
    top: 70%;
    left: 80%;
  }

  .navbar-menu-responsive {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  .navbar-menu-responsive img {
    width: 20px;
    height: 20px;
    margin: 22px 22px;
  }

  .navbar-modal-button {
    justify-content: end;
    margin-top: 28px;
    margin-bottom: 50px;
    margin-right: 14px;
  }

  .exit-nav-modal {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  .exit-nav-modal img {
    width: 20px;
    height: 20px;
    margin: 22px 22px;
  }

  .backdrop-modal-nav {
    width: 100%;
    height: 10 0vh;
    background-image: url(../image/navModal.png);
    background-color: black;
    object-fit: cover;
  }

  .navbar-content-modal {
    flex-direction: column-reverse;
    margin-left: 28px;
    gap: 60px;
  }

  .nav-content-bio h1 {
    font-size: 24.04px;
  }

  .nav-content-bio p {
    font-size: 10.68px;
    width: 329.85px;
  }

  .button-head-office {
    width: 173.18px;
    font-size: 10.68px;
    color: rgba(59, 69, 89, 0.8) !important;
    padding: 8px 0px;
    cursor: pointer;
  }

  .button-office {
    width: 145.18px;
    font-size: 10.68px;
    color: rgba(59, 69, 89, 0.8) !important;
    padding: 8px 0px;
    cursor: pointer;
  }

  .nav-content-list {
    font-size: 32.25px;
  }

  /* Landing */
  .h-landing-responsive {
    height: 100%;
  }
  .image-landing {
    width: 100% !important;
  }
  .text-landing-responsive {
    width: 100%;
    padding: 3rem 0 0;
    margin-bottom: 5rem;
  }
  .mb-responsive-landing {
    margin-bottom: 5rem;
  }
  .btn-landing-responsive {
    width: 100%;
    padding: 8.68085px 17.3617px;
    margin-top: 50px;
  }
  .pt-img-landing-responsive {
    padding-top: 5rem;
  }

  .btn-landing-responsive {
    width: 220px;
    height: 54px;
    margin-left: auto;
    margin-right: auto;
  }

  .btn-landing-responsive > .svg-wrapper-1 {
    margin-left: 20px;
  }

  .btn-landing-responsive span {
    font-size: 20px;
  }

  /* Product */
  .top-responsive-products {
    margin-bottom: 59px;
  }

  .rectangle {
    width: 17.42px;
    height: 17.42px;
    border: 3px solid #ffc107;
    margin-top: 65px;
  }

  .products-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .top-products-wrap {
    width: 600px;
    padding-left: 15px;
    margin-bottom: 26.05px;
  }

  .title-products-responsive {
    font-size: 12px;
  }

  .titleBot-products-responsive {
    font-size: 25px;
    line-height: 5px;
  }

  .content-title-product {
    left: 20px;
    bottom: 5px;
    font-size: 10.7302px;
  }

  .hover-title-product {
    font-size: 15px;
    margin-top: 30px;
  }

  .content-poster-product {
    width: 166.85px;
  }

  .content-desc-product {
    width: 166.85px;
    height: 169px;
    position: absolute;
    background: rgba(235, 111, 62, 0.75);
    bottom: 0;
  }

  .hover-desc-product {
    font-size: 9px;
  }

  /* experience */
  .experience-bottom-responsive {
    margin-bottom: 18%;
  }

  .top-experience-wrap {
    margin-bottom: 23.78px;
  }

  .title-experience-responsive {
    font-size: 24px;
    margin-bottom: 6.44px;
  }

  .titleBot-experience-responsive {
    font-size: 10px;
    line-height: 10.47px;
  }

  .showCard-experience {
    margin-bottom: 10.07px;
  }

  .base-content-experience {
    margin-bottom: 18px;
  }

  .content-experience-wrap {
    flex-direction: column !important;
    margin: auto;
  }

  .content-experience-wrap-2 {
    flex-direction: column-reverse !important;
    margin: 28.98px auto;
  }

  .experience-text-porto {
    font-weight: 800;
    font-size: 8px;
    line-height: 11px;
  }

  .experience-text-title {
    width: 332px;
    font-weight: 700;
    font-size: 23.743px;
    line-height: 30px;
    color: #ffffff;
  }

  .content-experience-image {
    width: 333.33px;
    margin-bottom: 18.5px;
  }

  .experience-text-desc {
    width: 293.58px;
    font-weight: 500;
    font-size: 10.3212px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.75);
  }

  .btn-showAll {
    width: 167px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
    font-weight: 700;
  }

  .top-modal-experience-wrap {
    margin-bottom: 21px;
    font-size: 17.41px;
  }

  .top-modal-arrow {
    font-size: 12px;
    font-style: italic;
    display: inline;
  }

  .base-modal-content {
    gap: 10px;
  }

  .modal-content-wrap {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 7px;
  }

  .modal-content-wrap h1 {
    width: 328px;
    color: #ffffff;
    font-size: 23.46px;
  }

  .modal-content-wrap p {
    width: 290.04px;
    font-size: 10.2px;
  }

  .modal-content-wrap img {
    width: 350px;
  }

  .modal-content-wrap-2,
  .modal-content-wrap-4,
  .modal-content-wrap-6 {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    padding: 0 7px;
  }

  .modal-content-wrap-2 h1,
  .modal-content-wrap-4 h1,
  .modal-content-wrap-6 h1 {
    width: 328px;
    color: #ffffff;
    font-size: 23.46px;
  }

  .modal-content-wrap-2 p,
  .modal-content-wrap-4 p,
  .modal-content-wrap-6 p {
    width: 290.04px;
    font-size: 10.2px;
  }

  .modal-content-wrap-2 img,
  .modal-content-wrap-4 img,
  .modal-content-wrap-6 img {
    width: 350px;
  }

  /* Team */

  .top-team-responsive {
    margin-bottom: 23.9px;
    padding: 0px 17px;
  }

  .top-team-responsive h1 {
    font-size: 24px;
  }

  .main-poster {
    width: 194.06px;
    height: 199px;
    margin-top: 24px;
    padding: 20px;
    border: 2px #68500d solid;
    border-radius: 10px;
  }

  .info-team {
    text-align: center;
    margin: auto;
  }

  .info-team img {
    width: 112.75px;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  .name-main-poster {
    font-size: 14.37px;
    line-height: 0px;
  }

  .title-main-poster {
    font-size: 8.98px;
    color: #9f9f9f;
  }

  .poster-slider {
    width: 25%;
    margin: 0px auto;
  }

  .card-leader-bio {
    text-align: center;
  }

  .card-leader-bio img {
    width: 69.95px;
    height: 69.95px;
    margin: 20px auto;
    cursor: pointer;
  }

  .name-slider {
    font-size: 7.87px;
    line-height: 0px;
  }

  .title-slider {
    font-style: italic;
    font-size: 7.87px;
    line-height: 0px;
    color: #9f9f9f;
  }

  .top-team-modal {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .top-modal-title {
    font-size: 19.57px;
    margin-bottom: 20px;
  }

  .top-modal-desc {
    font-size: 8.15px;
    margin-bottom: 15px;
  }

  .box-record {
    width: 100px;
  }

  .title-record {
    font-size: 26.09px;
    margin-bottom: 5px;
  }

  .bot-title-record {
    font-size: 8.15px;
  }

  .top-frame-poto img {
    width: 326px;
    height: 217.33px;
    object-fit: cover;
    border-radius: 6.52px;
  }

  .top-team-title {
    font-size: 18.94px;
    margin-bottom: 40px;
  }

  .leader-part-left {
    margin-bottom: 20px;
  }

  .content-team-leader-1,
  .content-team-leader-2,
  .content-team-leader-3,
  .content-team-leader-4,
  .content-team-leader-5 {
    flex-direction: column;
    align-items: center;
  }

  .frame-leader-photo-1,
  .frame-leader-photo-2,
  .frame-leader-photo-3,
  .frame-leader-photo-4,
  .frame-leader-photo-5 {
    width: 220.5px;
    height: 311.67px;
    padding: 11px;
    border: 1.5125px solid #ffc107;
    background-color: black;
  }

  .frameOut-leader-photo-1,
  .frameOut-leader-photo-2,
  .frameOut-leader-photo-3,
  .frameOut-leader-photo-4,
  .frameOut-leader-photo-5 {
    width: 220.5px;
    height: 311.67px;
    border: 1.5125px solid #ffc107;
    background-color: black;
    margin-left: 18px;
    margin-top: 18px;
  }

  .frame-leader-photo-1 img,
  .frame-leader-photo-2 img,
  .frame-leader-photo-3 img,
  .frame-leader-photo-4 img,
  .frame-leader-photo-5 img {
    width: 195.23px;
    height: 236.36px;
    margin-bottom: 12px;
  }

  .name-frame-leader-1,
  .name-frame-leader-2,
  .name-frame-leader-3,
  .name-frame-leader-4,
  .name-frame-leader-5 {
    font-size: 15.86px;
    line-height: 15px;
  }

  .position-frame-leader-1,
  .position-frame-leader-2,
  .position-frame-leader-3,
  .position-frame-leader-4,
  .position-frame-leader-5 {
    font-size: 9.91px;
    font-style: italic;
    color: rgba(159, 159, 159, 1);
    line-height: 0px;
  }

  .content-leader-title {
    font-size: 19.56px;
  }

  .content-leader-desc {
    font-size: 8.15px;
    text-align: justify;
  }

  .top-team-title {
    margin-bottom: 20px;
  }

  .card-content-team {
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 30px;
  }

  .frame-team-photo {
    width: 137.54px;
    height: 194.42px;
    padding: 7px;
    background: #171717;
    border: 1.175px solid #ffc107;
  }

  .frame-team-photo img {
    width: 121.78px;
    height: 147.43px;
    margin-bottom: 8px;
  }

  .frameOut-team-photo {
    width: 137.54px;
    height: 194.42px;
    background: #171717;
    border: 1.175px solid #ffc107;
    margin-left: 10px;
    margin-top: 10px;
  }

  .name-frame-team {
    font-size: 9.89px;
    margin-bottom: 1px;
  }

  .position-frame-team {
    font-style: italic;
    font-size: 6.18px;
    color: rgba(159, 159, 159, 1);
  }

  .card-new-team-container {
    margin: 0px 5px;
  }

  .card-new-team {
    width: 124px;
    height: 127px;
  }

  .card-new-team-name {
    font-size: 14px;
  }

  .card-new-team-position {
    font-size: 11px;
  }

  /* Contact */
  .form-divide {
    flex-direction: column;
  }
}

/* sm */
@media only screen and (min-width: 576px) {
  /* Background */
  .bg-height-responsive {
    min-height: 850vmax;
  }

  /* Navbar */
  .navbar-nav-responsive {
    padding-top: 0.5rem;
  }
  .navbar-brand-responsive {
    position: absolute;
    top: 10%;
    right: -25%;
  }

  /* Landing */
  .h-landing-responsive {
    height: 100%;
  }
  .mb-responsive-landing {
    margin-bottom: 5rem;
  }
  .pt-img-landing-responsive {
    padding-top: 3rem;
  }
}

/* tab */
@media only screen and (min-width: 600px) {
  /* Navbar */
  .navbar-nav-responsive {
    padding-top: 0.5rem;
  }
  .navbar-brand-responsive {
    position: absolute;
    top: 40%;
    right: -10%;
  }
  .button-nav-responsive {
    position: absolute;
    top: 70%;
    left: 0;
    z-index: 10;
  }

  /* Landing */
  .h-landing-responsive {
    height: 100%;
  }
  .image-landing {
    width: 100%;
  }
  .text-landing-responsive {
    width: 100%;
    padding: 3rem 0 0;
    margin-bottom: 5rem;
  }
  .mb-responsive-landing {
    margin-bottom: 5rem;
  }
  .btn-landing-responsive {
    width: 318px;
    height: 72px;
    padding: 0.5rem 1rem;
    margin-top: 50px;
  }
  .pt-img-landing-responsive {
    padding-top: 5rem;
  }

  /* Product */
  .top-responsive-products {
    margin-bottom: 3rem;
  }

  .item {
    width: 100%;
  }
  .item img.card-experience {
    width: 15rem;
  }
  .inner-carousel {
    display: flex;
    gap: 1rem;
    background: transparent;
  }
}

/* md */
@media only screen and (min-width: 768px) {
  /* Background */
  .bg-height-responsive {
    min-height: 700vmax;
  }

  /* Navbar */
  .navbar-brand-responsive {
    position: absolute;
    top: 10%;
    right: -8%;
  }

  /* Landing */
  .text-landing-responsive {
    width: 100%;
  }
  .image-landing {
    width: 100%;
  }
  .pt-img-landing-responsive {
    padding-top: 3rem;
  }
  .btn-landing-responsive {
    width: 318px;
    height: 72px;
    padding: 0.5rem 1rem;
    margin-top: 50px;
  }
}

/* lg */
@media only screen and (min-width: 992px) {
  /* Background */
  .bg-height-responsive {
    min-height: 850vmax;
  }

  /* Navbar */
  .justify-end-responsive {
    justify-content: end !important;
  }
  .flex-responsive {
    display: flex;
  }
  .navbar-nav-responsive {
    padding-top: 1.5rem;
  }
  .button-nav-responsive {
    position: relative;
    /* top: 70%;
		left: -5%;
		z-index: 10; */
  }
  .nav-item-product {
    padding-right: 1.5rem;
  }
  .nav-item-2 {
    padding: 0 1.5rem;
  }
  .nav-item-contact {
    padding-left: 1.5rem;
  }

  /* Landing */
  .h-landing-responsive {
    height: 130vh;
    width: 100%;
  }
  .image-landing {
    position: absolute;
    width: 60%;
    top: 25%;
    right: -5%;
  }
  .padding-top-landing {
    padding-top: 10rem;
  }
  .text-landing-responsive {
    width: 85%;
  }
  .landing-bottom-responsive {
    margin-bottom: 18%;
  }

  /* Product */
  .h-products-responsive {
    height: 145vh;
  }
  .mb-responsive-products {
    margin-bottom: 7rem;
  }
  .top-responsive-products {
    margin-bottom: 5rem;
    padding-top: 3rem;
  }
  .product-bottom-responsive {
    margin-bottom: 5%;
  }

  /* Experience */
  .h-experience-responsive {
    height: 120vh;
  }
  .experience-bottom-responsive {
    margin-bottom: 18%;
  }
  .item {
    width: 32%;
  }
  .item img.card-experience {
    width: 20rem;
    /* height: 50%; */
  }
  .inner-carousel {
    display: flex;
    gap: 1.5rem;
    background: transparent;
  }

  .card-new-team-container {
    margin: 0px 20px;
  }

  .card-new-team {
    width: 184px;
    height: 187px;
  }

  .card-new-team-name {
    font-size: 18px;
  }

  .card-new-team-position {
    font-size: 14px;
  }

  /* Contact */
  .h-contact-responsive {
    height: 102vh;
  }
  .bottom-form {
    margin-bottom: 8.5rem;
  }
}
