@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&family=Red+Hat+Display:wght@400;500;600;700;800&display=swap");

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/** Scrollbar */
::-webkit-scrollbar {
	width: 8px;
	max-height: 20px;
	height: 10px;
}
::-webkit-scrollbar-track {
	background: #0d0d0d;
}
::-webkit-scrollbar-thumb {
	background: #0d0d0d;
	/* border-radius: 50px 0 0 50px; */
}

/* Fonts Manrope */
/* @font-face {
	font-family: "Manrope-Bold";
	src: url("./fonts/Manrope/bold.otf") format("truetype");
}
@font-face {
	font-family: "Manrope-Medium";
	src: url("./fonts/Manrope/medium.otf") format("truetype");
}
@font-face {
	font-family: "Manrope-Regular";
	src: url("./fonts/Manrope/regular.otf");
	font-weight: 200 !important;
} */

/* Fonts RedHatDisplay */
/* @font-face {
	font-family: "RedHatDisplay-Black";
	src: url("./fonts/RedHatDisplay/RedHatDisplay-Black.otf") format("truetype");
}
@font-face {
	font-family: "RedHatDisplay-Bold";
	src: url("./fonts/RedHatDisplay/RedHatDisplay-Bold.otf") format("truetype");
}
@font-face {
	font-family: "RedHatDisplay-Medium";
	src: url("./fonts/RedHatDisplay/RedHatDisplay-Medium.otf") format("truetype");
}
@font-face {
	font-family: "RedHatDisplay-Regular";
	src: url("./fonts/RedHatDisplay/RedHatDisplay-Regular.otf") format("truetype");
	font-weight: 200 !important;
} */
